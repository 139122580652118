@use "../../scss/" as *;

.movie-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 3rem;

  @include table {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

.movies-search {
  position: relative;
  width: 100%;
  max-width: 500px;

  input {
    border: 0;
    background-color: $black;
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    border-radius: $border-radius;
    color: $text-color;
    font-family: $font-family;
    border: none;
    outline: none;
    width: 100%;
    padding-right: 8rem;
  }

  .btn {
    position: absolute;
    right: 0;
    top: 0;

    @include mobile {
      right: 1px;
      top: 1px;
    }
  }
}

.movie-grid__loadmore {
  @include flex(center, center);
}
