@use "../../scss/" as *;

.footer {
  position: relative;
  padding: 6rem 2rem;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;

  &__content {
    &__logo {
      @include flex(center, center);
      margin-bottom: 3rem;
    }

    &__menus {
      @include flex(center, center);
      width: 100%;
    }

    &__menu {
      @include flex(flex-start, flex-start);
      flex-direction: column;
      width: 25%;
      margin-top: 1rem;

      font-size: 1.5rem;
      font-weight: 600;

      & > * ~ * {
        margin-top: 1rem;
      }
    }
  }
}
