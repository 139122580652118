$body-bg: #0f0f0f;
$main-color: #ff0000;
$text-color: #fff;
$white: white;
$black: black;

$box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

$header-height: 8rem;
$header-shrink-height: 5rem;
$border-radius: 30px;
$mobile-width: 600px;
$table-width: 1024px;

$font-family: "Montserrat", sans-serif;
