@use "./scss/" as *;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  _webkit-tap-highlight-color: transparent;
}

html {
  font-size: 100%;

  @include table {
    font-size: 80%;
  }

  @include mobile {
    font-size: 60%;
  }
}

body {
  font-family: $font-family;
  font-weight: 400;
  line-height: 1.5;

  background-color: $body-bg;
  color: $text-color;

  @include mobile {
    margin-bottom: 3rem;
  }
}

a {
  text-decoration: none;
  color: unset;
}

@media (hover: hover) {
  a:hover {
    color: $main-color;
  }
}

button,
input {
  outline: 0;
}

ul {
  list-style-type: none;
}

img {
  max-width: 100%;
}

iframe {
  border: 0;
}

.container {
  max-width: 1660px;
  margin: auto;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.section {
  padding: 0 2rem;
}

.section__header {
  @include flex(space-between, center);
}
