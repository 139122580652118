@use "./variables.scss" as *;

@mixin mobile {
  @media only screen and (max-width: $mobile-width) {
    @content;
  }
}

@mixin table {
  @media only screen and (max-width: $table-width) {
    @content;
  }
}
